import { cn } from "~utils";

type SeedWaveProps = {
  className: string;
  seedPatternColor?: string;
  variant?: "base" | "xl";
};

export function SeedWave({
  className,
  seedPatternColor,
  variant = "base",
  ...rest
}: SeedWaveProps) {
  return (
    <div
      className={cn(
        className,
        "pointer-events-none",
        "[mask-image:url(/masks/seed-wave.svg)]",
        variant === "base"
          ? cn(
              // mobile
              "[mask-position:15%] [mask-size:auto_150%]",

              // desktop
              "lg:[mask-position:bottom_min(33%,-13vw)_left]",

              // desktop:xl
              "xl:[mask-position:bottom_min(33%,-10vw)_left] xl:[mask-size:cover]",
            )
          : cn(
              // mobile
              "[mask-position:bottom_min(25%,-4vw)_left_10%] [mask-size:140%]",

              // desktop
              "lg:[mask-position:bottom_min(25%,-7vw)_left_10%]",

              // desktop:xl
              "xl:[mask-position:bottom_min(25%,-9vw)_left_20%]",
            ),
      )}
      style={seedPatternColor ? { background: seedPatternColor } : {}}
      {...rest}
    />
  );
}
